// import NFTList from "./nfts/list/NFTList";
// import NFTCreate from "./nfts/create/NFTCreate";

export const routes = [
  // {
  //   path: "/marketplace",
  //   component: NFTList,
  //   exact: true
  // },
  // {
  //   path: "/marketplace/create",
  //   component: NFTCreate,
  //   exact: true
  // }
];
