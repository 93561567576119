import RouteHome from "../../routes/home/RouteHome";
import RouteDashboard from "../../routes/dashboard/RouteDashboard";
import RouteLogin from "../../routes/login/RouteLogin";
import RouteAssetsContainer from "../../routes/assets/RouteAssetsContainer";
import RouteNotificationsContainer from "../../routes/notifications/RouteNotificationsContainer";
import RouteTournamentContainer from "../../routes/tournaments/RouteTournamentContainer";
import RouteCommunicationContainer from "../../routes/communication/RouteCommunicationContainer";
import RouteUsersContainer from "../../routes/users/RouteUsersContainer";
import RouteReportsContainer from "../../routes/reports/RouteReportsContainer";
import RouteGameContainer from "../../routes/game/RouteGameContainer";
import RouteMatchesContainer from "../../routes/matches/RouteMatchesContainer";
import RouteGamesContainer from "../../routes/games/RouteGamesContainer";
import RouteChallengeContainer from "../../routes/challenge/RouteChallengeContainer";
import RouteWalletContainer from "../../routes/wallet/RouteWalletContainer";
import RouteLeagueContainer from "../../routes/leagues/RouteLeagueContainer";
import RouteMarketplaceContainer from "../../routes/marketplace/RouteMarketplaceContainer";
import RouteOrganizationsContainer from "../../routes/organizations/RouteOrganizationsContainer";
import TechSupport from "../../routes/TechSupport";

// for not authorized user
/**unauthorized users will redirect to the login page if unauthorized routes do not have the same path*/
export const layoutLoggedRoutes = [
  {
    exact: true,
    path: "/",
    component: RouteDashboard
  },
  {
    path: "/dashboard",
    component: RouteDashboard
  },
  {
    path: "/assets",
    component: RouteAssetsContainer
  },
  {
    path: "/challenge/:token",
    component: RouteChallengeContainer
  },
  {
    path: "/game/:token",
    component: RouteGameContainer
  },
  {
    path: "/games",
    component: RouteGamesContainer
  },
  {
    path: "/matches",
    component: RouteMatchesContainer
  },
  {
    path: "/reports",
    component: RouteReportsContainer
  },
  {
    path: "/tournaments",
    component: RouteTournamentContainer
  },
  {
    path: "/leagues",
    component: RouteLeagueContainer
  },
  {
    path: "/login",
    component: RouteLogin
  },
  {
    path: "/notifications",
    component: RouteNotificationsContainer
  },
  {
    path: "/marketplace",
    component: RouteMarketplaceContainer
  },
  {
    path: "/communication",
    component: RouteCommunicationContainer
  },
  {
    path: "/users",
    component: RouteUsersContainer
  },
  {
    path: "/wallet",
    component: RouteWalletContainer
  },
  {
    path: "/organizations",
    component: RouteOrganizationsContainer
  },
  {
    path: "/tech-support",
    component: TechSupport
  }
];

// for not authorized user
export const layoutGeneralRoutes = [
  {
    exact: true,
    path: "/",
    component: RouteHome
  },
  {
    path: "/login",
    component: RouteLogin
  },
  {
    path: "/tech-support",
    component: TechSupport
  }
];
