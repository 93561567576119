import DashboardIcon from "../../../static/icons/dashboard-icon.svg";
import AssetsIcon from "../../../static/icons/assets-icon.svg";
import GamesIcon from "../../../static/icons/controller.svg";
import EmailIcon from "../../../static/icons/email-icon.svg";
import NotificationsIcon from "../../../static/icons/at-sign-icon.svg";
import ReportsIcon from "../../../static/icons/reports-icon.svg";
import TournamentsIcon from "../../../static/icons/trophy-icon.svg";
import UsersIcon from "../../../static/icons/users-icon.svg";
import OrganizationsIcon from "../../../static/icons/icon-reg-organization.svg";

export const NavItems = [
  {
    title: "Dashboard",
    to: "/dashboard",
    icon: DashboardIcon
  },
  {
    title: "Assets",
    to: "/assets",
    icon: AssetsIcon,
    subItems: [
      {
        title: "Rival Config",
        to: "/assets/config"
      },
      {
        title: "Home",
        to: "/assets/dashboard"
      },
      {
        title: "Game",
        to: "/assets/game"
      },
      {
        title: "Email",
        to: "/assets/email",
        checkPermission: true
      }
    ]
  },
  {
    title: "Email",
    to: "/communication",
    icon: EmailIcon,
    subItems: [
      {
        title: "Create",
        to: "/communication/create"
      },
      {
        title: "Scheduled",
        to: "/communication/scheduled"
      },
      {
        title: "Drafts",
        to: "/communication/drafts"
      },
      {
        title: "Sent",
        to: "/communication/sent"
      },
      {
        title: "Templates",
        to: "/communication/templates"
      },
      {
        title: "Global Templates",
        to: "/communication/global_templates",
        checkPermission: true
      }
    ]
  },
  {
    title: "Game Titles",
    to: "/games",
    icon: AssetsIcon
  },
  {
    title: "Matches",
    to: "/matches",
    alternates: ["game", "challenge"],
    icon: GamesIcon
  },
  {
    title: "Notifications",
    to: "/notifications",
    icon: NotificationsIcon
  },
  {
    title: "Organizations",
    to: "/organizations",
    icon: OrganizationsIcon,
    subItems: [
      {
        title: "Organization",
        to: "/organizations"
      },
      {
        title: "Groups",
        to: "/organizations/groups"
      }
    ]
  },
  {
    title: "Reports",
    to: "/reports",
    icon: ReportsIcon,
    subItems: [
      {
        title: "Event Registration",
        to: "/reports/tournament"
      },
      {
        title: "Graphs",
        to: "/reports/graphs",
        disabled: true
      },
      {
        title: "Membership List",
        to: "/reports/membership"
      },
      {
        title: "Site Analytics",
        to: "/reports/analytics"
      },
      {
        title: "API Documentation",
        href:
          process.env.NODE_ENV === "development"
            ? `http://localhost:4000`
            : `https://${
                process.env.NODE_ENV === "production" &&
                !window?.location?.hostname?.includes("luigi")
                  ? "portal."
                  : "portal.luigi."
              }rivalgames.com`
      }
    ]
  },
  // {
  //   title: "Marketplace",
  //   to: "/marketplace",
  //   icon: AssetsIcon,
  //   subItems: [
  //     {
  //       title: "NFTs",
  //       to: "/marketplace"
  //     }
  //   ]
  // },
  {
    title: "Tournaments",
    to: "/tournaments",
    icon: TournamentsIcon,
    subItems: [
      {
        title: "Create",
        to: "/tournaments/create"
      },
      {
        title: "Templates",
        to: "/tournaments/templates"
      },
      {
        title: "Calendar",
        to: "/tournaments/calendar"
      }
    ]
  },
  {
    title: "Leagues",
    to: "/leagues",
    icon: TournamentsIcon,
    subItems: [
      {
        title: "Create",
        to: "/leagues/create"
      }
    ]
  },
  {
    title: "Users",
    to: "/users",
    icon: UsersIcon
  },
  {
    title: "Wallet",
    to: "/wallet",
    icon: AssetsIcon
  }
];
