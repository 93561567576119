import React, { Component } from "react";
import { connect } from "react-redux";
import { verifyPermission } from "../../helpers/common";
import { Container, ContentCard, Header, Filter, FilterItem } from "./styled";
import { Switch, Route, withRouter } from "react-router-dom";
import { routes } from "./routes";

class RouteMarketplace extends Component {
  handleChangeFilter = value => {
    this.setState({ filter: value });
  };

  render() {
    return (
      <Container>
        <ContentCard>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  render={() => {
                    return (
                      <Header>
                        <Filter>
                          <FilterItem
                            key={"marketplace"}
                            to={"/marketplace"}
                            isActive={
                              window.location.pathname === "/marketplace"
                            }
                            onClick={() =>
                              this.handleChangeFilter("marketplace")
                            }
                          >
                            ALL NFTs
                          </FilterItem>
                          {/* {verifyPermission(
                            "/marketplace/create",
                            this.props.permissions
                          ) === "admin" && (
                            <FilterItem
                              key={"create"}
                              to={"/marketplace/create"}
                              isActive={
                                window.location.pathname ===
                                "/marketplace/create"
                              }
                              onClick={() => this.handleChangeFilter("create")}
                            >
                              CREATE NFT
                            </FilterItem>
                          )} */}
                        </Filter>
                      </Header>
                    );
                  }}
                />
              );
            })}
          </Switch>
          <Switch>
            {routes.map(route => {
              const Component = route.component;
              return (
                <Route
                  key={route.path}
                  exact={route.exact}
                  path={route.path}
                  render={() => {
                    return (
                      <Component
                        {...this.props}
                        user={this.props.user}
                        permission={verifyPermission(
                          !!route.altPermission
                            ? route.altPermission
                            : route.path,
                          this.props.permissions
                        )}
                      />
                    );
                  }}
                />
              );
            })}
          </Switch>
        </ContentCard>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  permissions: state.permissionsState.permissions
});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RouteMarketplace));
