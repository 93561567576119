import React from "react";

export default function TechSupport() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "blue",
        color: "yellow",
        height: "100%"
      }}
    >
      <h1>Tech Support</h1>
      <h1>Available 24/7</h1>
      <h1>Call Connor Kerry at (303) 653-1216</h1>
    </div>
  );
}
